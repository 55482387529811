import {CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {OidcWidgetModule} from '../../../oidc-widget/src/lib/oidc-widget.module';
import {HttpClientModule} from "@angular/common/http";
import {createCustomElement} from "@angular/elements";
import {OidcWidgetComponent} from '../../../oidc-widget/src/lib/oidc-widget.component';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        OidcWidgetModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule implements DoBootstrap {
    constructor(injector: Injector) {
        if (!customElements.get('oidcauth-widget')) {
            const custom = createCustomElement(OidcWidgetComponent, {injector});
            customElements.define('oidcauth-widget', custom);
        }
    }

    ngDoBootstrap(): void {
        // This is intentional
    }
}
