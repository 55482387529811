import {NgModule} from '@angular/core';
import {OidcWidgetComponent} from './oidc-widget.component';
import {HttpClientModule} from "@angular/common/http";
import {BrowserModule} from "@angular/platform-browser";
import {OAuthModule} from "angular-oauth2-oidc";

@NgModule({
    declarations: [
        OidcWidgetComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        OAuthModule.forRoot()
    ],
    exports: [OidcWidgetComponent],
    providers: []
})
export class OidcWidgetModule {
}
