import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {AuthConfig, OAuthErrorEvent, OAuthService} from "angular-oauth2-oidc";
import {filter} from 'rxjs';
import OidcAuthEvents, {AuthenticationReadyEvent, LoginInitEvent, LoginStateRequestEvent} from "@vdab/oidc-events";

@Component({
    templateUrl: './oidc-widget.component.html',
    selector: 'oidcauth-widget',
    styles: []
})
export class OidcWidgetComponent implements OnInit, OnChanges {

    @Input()
    public issuer: string;
    @Input("client-id") // no camel/uppercase: https://html.spec.whatwg.org/multipage/custom-elements.html#custom-elements-core-concepts
    public clientId: string;
    @Input()
    public scope = 'openid profile entitlements ikl';
    @Input("silent-refresh-path") // no camel/uppercase: https://html.spec.whatwg.org/multipage/custom-elements.html#custom-elements-core-concepts
    public silentRefreshPath: string;
    @Output()
    authenticatedEmit: EventEmitter<AuthenticationReadyEvent> = new EventEmitter();

    authenticationReady = false;
    authenticationStarted = false;

    constructor(private oAuthService: OAuthService) {
    }

    ngOnChanges(): void {
        // ngOnInit should be enough, but it seems webcomponents don't get the regular lifecycle
        // ( https://angular.io/guide/lifecycle-hooks ) and ngOnInit() is called before all parameters are set
        this.authenticate();
    }

    ngOnInit(): void {
        this.authenticate();
    }

    authenticate(): void {
        // see comment in ngOnChanges()
        // if this checks passes, all parameters are passed, including optional ones
        if (!this.clientId || !this.issuer || this.authenticationStarted) {
            return;
        }
        this.authenticationStarted = true;
        if (!this.isRedirectAfterLoginScreen()) {
            this.oAuthService.logOut(true); // remove tokens on page display to get fresh ones
        }

        const authConfig = this.getAuthConfig(this.silentRefreshPath);
        this.oAuthService.configure(authConfig);
        this.authenticationReady = false;
        this.oAuthService.loadDiscoveryDocumentAndTryLogin()
            .then(() => this.runAfterAuthFlow());
    }

    private runAfterAuthFlow() {
        this.startLoginListener();
        this.startSilentRefresh();
    }

    private startLoginListener() {
        OidcAuthEvents.addLoginInitListener((loginInitEvent: LoginInitEvent) => {
            const context = loginInitEvent.context;
            const params = {context};
            this.oAuthService.initCodeFlow('', params);
        });
    }

    private startLoginStateRequestListener() {
        OidcAuthEvents.addLoginStateRequestListener((loginStateRequestEvent: LoginStateRequestEvent) => {
            if (this.oAuthService.hasValidAccessToken()) {
                this.handleAuthenticationReady();
            }
        });
    }

    private startSilentRefresh() {
        this.oAuthService.events
            .pipe(filter(event => event.type === 'token_received'))
            .subscribe(() => this.handleAuthenticationReady());
        this.oAuthService.silentRefresh({}, true)
            .catch(error => {
                this.handleAuthenticationReady()
                if (error instanceof OAuthErrorEvent) {
                    const errorDetails = error.reason?.['params']?.['error'];

                    if (errorDetails === 'login_required') {
                        console.log('authentication flow done, login required');
                        return;
                    }
                }
                console.error('authentication flow failed', error);
            })
            .then(() => this.oAuthService.setupAutomaticSilentRefresh());
    }

    private handleAuthenticationReady() {
        this.authenticationReady = true;
        console.log('authentication flow done, token received');

        const idToken = this.oAuthService.getIdToken();
        const accessToken = this.oAuthService.getAccessToken();
        const idTokenExpirationTimestamp = this.oAuthService.getIdTokenExpiration();
        const accessTokenExpirationTimestamp = this.oAuthService.getAccessTokenExpiration();
        const identityClaims = this.oAuthService.getIdentityClaims();
        const authenticationReadyEvent: AuthenticationReadyEvent = {
            clientId: this.clientId,
            issuer: this.issuer,
            idToken,
            accessToken,
            idTokenExpiration: new Date(idTokenExpirationTimestamp),
            accessTokenExpiration: new Date(accessTokenExpirationTimestamp),
            identityClaims
        };
        this.authenticatedEmit.emit(authenticationReadyEvent);
        OidcAuthEvents.dispatchAuthenticationReady(authenticationReadyEvent);
    }

    private getAuthConfig(silentRefreshPath: string): AuthConfig {
        const path = silentRefreshPath ?? this.getDefaultSilentRefreshPath();
        const actualSilentRefreshRedirectUri = this.isValidHttpUrl(path) ? path : this.getFullUrl(path);
        return {
            // Url of the Identity Provider
            issuer: this.issuer,

            // URL of the SPA to redirect the user to after login
            redirectUri: window.location.protocol + '//' + window.location.host + window.location.pathname,

            // The SPA's id. The SPA is registered with this id at the auth-server
            clientId: this.clientId,

            // Just needed if your auth server demands a secret. In general, this
            // is a sign that the auth server is not configured with SPAs in mind
            // and it might not enforce further best practices vital for security
            // such applications.
            // dummyClientSecret: 'secret',
            responseType: 'code',

            // set the scope for the permissions the client should request
            // The api scope is a usecase specific one
            scope: this.scope,

            // https in containers gaat nogal moeilijk zijn
            // je zou voor ieder van die containers een prive sleutel + certificaat moeten genereren,
            // gesigned door een CA die door alle containers is aanvaard
            // installatie van die sleutel + cert is voor elke container anders:
            // apache werkt met pkcs12
            // keycloak met java keystores
            // build container nog anders
            // zou op omgevingen true mogen zijn, maar maakt eigenlijk niet uit: we weten wel dat het https is
            requireHttps: false,
            showDebugInformation: true,
            strictDiscoveryDocumentValidation: false, // this is about making sure that _issuer_ is part of every url, but it's not
            silentRefreshRedirectUri: actualSilentRefreshRedirectUri,
            useSilentRefresh: true,
            silentRefreshTimeout: 20000,
        }
    }

    private getFullUrl(path: string) {
        return window.location.protocol + '//' + window.location.host + '/' + path;
    }

    private getDefaultSilentRefreshPath(): string {
        const parsedContextPaths = window.location.pathname.split("/")
            .filter(pathPart => pathPart);
        return (parsedContextPaths[0] || "oidc-widget") + '/silent-refresh.html';
    }

    private isRedirectAfterLoginScreen() {
        return window.location.href.includes('code') && window.location.href.includes('state');
    }

    private isValidHttpUrl(path: string): boolean {
        let url;

        try {
            url = new URL(path);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }
}
